<template>
  <el-table
    :data="dataList"
    :header-cell-style="{ color: '#333333', background: '#EFF6FF' }"
    height="100%"
    border
  >
    <el-table-column
      label="供货日期"
      prop="date"
      align="center"
    ></el-table-column>
    <el-table-column label="结算日期" prop="convert_date" align="center">
      <template slot-scope="scope">
        <span>{{ scope.row.convert_date || "-" }}</span>
      </template>
    </el-table-column>
    <el-table-column
      label="集配中心"
      prop="logistic_business_name"
      align="center"
      min-width="120"
      show-overflow-tooltip
    >
      <template slot-scope="scope">
        <span>{{ scope.row.logistic_business_name || "-" }}</span>
      </template>
    </el-table-column>
    <el-table-column
      label="城市仓"
      prop="logistics_name"
      align="center"
      min-width="120"
      show-overflow-tooltip
    ></el-table-column>
    <el-table-column
      label="自提点"
      prop="delivery_name"
      align="center"
      min-width="120"
      show-overflow-tooltip
    ></el-table-column>
    <el-table-column
      label="支付时间"
      prop="pay_at"
      align="center"
      min-width="120"
    ></el-table-column>
    <el-table-column
      label="订单号"
      prop="order_no"
      align="center"
      min-width="180"
    ></el-table-column>
    <el-table-column
      label="商品名称"
      prop="product_title"
      align="center"
      min-width="150"
      show-overflow-tooltip
    >
    </el-table-column>
    <el-table-column
      label="货号"
      prop="sku_code"
      min-width="150"
      align="center"
    ></el-table-column>
    <el-table-column label="毛重" prop="gross_weight" align="center">
    </el-table-column>
    <el-table-column
      label="购买价"
      prop="price"
      align="center"
    ></el-table-column>
    <el-table-column
      label="购买数量"
      prop="qty"
      align="center"
    ></el-table-column>
    <el-table-column label="运费" prop="o_freight_amt" align="center">
    </el-table-column>
    <el-table-column label="服务费" prop="o_purchase_amt" align="center">
    </el-table-column>
    <el-table-column label="复核缺货" prop="stockout_number" align="center">
    </el-table-column>
    <el-table-column label="复核退运费" prop="qc_freight_amount" align="center">
    </el-table-column>
    <el-table-column
      label="复核退服务费"
      prop="qc_purchase_amount"
      align="center"
    >
    </el-table-column>
    <el-table-column
      label="运费金融手续费"
      prop="freight_service_fee"
      align="center"
    >
    </el-table-column>
    <el-table-column
      label="服务费金融手续费"
      prop="purchase_service_fee"
      align="center"
    >
    </el-table-column>
    <el-table-column label="运费结算" prop="freight_amount" align="center">
    </el-table-column>
    <el-table-column label="服务费结算" prop="purchase_amount" align="center">
    </el-table-column>
  </el-table>
</template>

<script>
export default {
  name: "TableList",
  props: {
    dataList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped></style>
