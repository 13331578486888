var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-table",
    {
      attrs: {
        data: _vm.dataList,
        "header-cell-style": { color: "#333333", background: "#EFF6FF" },
        height: "100%",
        border: "",
      },
    },
    [
      _c("el-table-column", {
        attrs: { label: "供货日期", prop: "date", align: "center" },
      }),
      _c("el-table-column", {
        attrs: { label: "结算日期", prop: "convert_date", align: "center" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _c("span", [_vm._v(_vm._s(scope.row.convert_date || "-"))]),
              ]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: {
          label: "集配中心",
          prop: "logistic_business_name",
          align: "center",
          "min-width": "120",
          "show-overflow-tooltip": "",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _c("span", [
                  _vm._v(_vm._s(scope.row.logistic_business_name || "-")),
                ]),
              ]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: {
          label: "城市仓",
          prop: "logistics_name",
          align: "center",
          "min-width": "120",
          "show-overflow-tooltip": "",
        },
      }),
      _c("el-table-column", {
        attrs: {
          label: "自提点",
          prop: "delivery_name",
          align: "center",
          "min-width": "120",
          "show-overflow-tooltip": "",
        },
      }),
      _c("el-table-column", {
        attrs: {
          label: "支付时间",
          prop: "pay_at",
          align: "center",
          "min-width": "120",
        },
      }),
      _c("el-table-column", {
        attrs: {
          label: "订单号",
          prop: "order_no",
          align: "center",
          "min-width": "180",
        },
      }),
      _c("el-table-column", {
        attrs: {
          label: "商品名称",
          prop: "product_title",
          align: "center",
          "min-width": "150",
          "show-overflow-tooltip": "",
        },
      }),
      _c("el-table-column", {
        attrs: {
          label: "货号",
          prop: "sku_code",
          "min-width": "150",
          align: "center",
        },
      }),
      _c("el-table-column", {
        attrs: { label: "毛重", prop: "gross_weight", align: "center" },
      }),
      _c("el-table-column", {
        attrs: { label: "购买价", prop: "price", align: "center" },
      }),
      _c("el-table-column", {
        attrs: { label: "购买数量", prop: "qty", align: "center" },
      }),
      _c("el-table-column", {
        attrs: { label: "运费", prop: "o_freight_amt", align: "center" },
      }),
      _c("el-table-column", {
        attrs: { label: "服务费", prop: "o_purchase_amt", align: "center" },
      }),
      _c("el-table-column", {
        attrs: { label: "复核缺货", prop: "stockout_number", align: "center" },
      }),
      _c("el-table-column", {
        attrs: {
          label: "复核退运费",
          prop: "qc_freight_amount",
          align: "center",
        },
      }),
      _c("el-table-column", {
        attrs: {
          label: "复核退服务费",
          prop: "qc_purchase_amount",
          align: "center",
        },
      }),
      _c("el-table-column", {
        attrs: {
          label: "运费金融手续费",
          prop: "freight_service_fee",
          align: "center",
        },
      }),
      _c("el-table-column", {
        attrs: {
          label: "服务费金融手续费",
          prop: "purchase_service_fee",
          align: "center",
        },
      }),
      _c("el-table-column", {
        attrs: { label: "运费结算", prop: "freight_amount", align: "center" },
      }),
      _c("el-table-column", {
        attrs: {
          label: "服务费结算",
          prop: "purchase_amount",
          align: "center",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }